import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import NFTExplained from "../components/nft_explained/nft_explained";
import Header from "../components/header/header";

function LoadingPage() {
  return (
    <ChakraProvider>
      <Header />
      <NFTExplained />
    </ChakraProvider>
  );
}

export default LoadingPage;
