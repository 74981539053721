import { Box } from "@chakra-ui/react";
import React from "react";
import {
  OpaliteButtonLogin,
  OpaliteButtonStandard,
} from "../shared/opalite_buttons";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteTextStandard, OpaliteTextTitle } from "../shared/opalite_text";
import BackgroundAmorphusGradient from "../../images/background_amorphus_gradient.svg";
import { navigate } from "gatsby";

interface NFTExplainedProps {}

const NFTExplained: React.FC<NFTExplainedProps> = (props) => {
  return (
    <Box
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      backgroundRepeat="no-repeat"
      style={{
        background: `url(${BackgroundAmorphusGradient})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "center 50px",
        backgroundColor: COLORS_PRIMARY.BACKGROUND,
      }}
      height="100vh"
    >
      <Box padding="2rem" width="80%" position="fixed" bottom=".25rem" left="0">
        <OpaliteTextTitle marginBottom=".5rem">
          What is an NFT?
        </OpaliteTextTitle>
        <OpaliteTextStandard marginBottom="1rem">
          We enable high-performing influencers like you to turn hundreds of
          posts into cash churning NFT's... all in the blink of an eye.
        </OpaliteTextStandard>
        <OpaliteButtonStandard onClick={() => navigate("/all_nft")}>
          Shop NFT's
        </OpaliteButtonStandard>
      </Box>
    </Box>
  );
};

export default NFTExplained;
